<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center">
      <v-col xl="6" lg="8" md="8" sm="10" xs="12">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="Contexte de rubrique" />
        </div>

        <!-- la progess bar à afficher lors du chargement des données -->
        <v-progress-linear indeterminate :active="loading"></v-progress-linear>

        <!-- le détail  -->
        <DetailViewComponent
          title="Détail du contexte de rubrique"
          :fields="fields"
        />
      </v-col>
    </v-row>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import DetailViewComponent from "@/components/ui/DetailViewComponent.vue";

import { RubricsContextService } from "@/service/sfr/rubrics_context_service.js";

import * as exceptions from "@/service/exception_to_message.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { addFieldWithOneValue } from "@/components/ui/detail_view_component_tool.js";

export default {
  name: "DetailRubricsContext",

  components: { Workflow, TitleAndReturnComponent, DetailViewComponent },
  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /** le service de chargement. */
      service: null,

      /**l'identifiant  à visualiser. */
      entityId: null,

      /**Afficher le bouton de retour */
      showBackButton: false,

      /**les champs à afficher en détail */
      fields: [],
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        this.fields = [];

        let entity = await this.service.getById(this.entityId);

        addFieldWithOneValue(this.fields, "label", entity.label, false);

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {},
  mounted() {

    this.service = new RubricsContextService(this.$api.getRubricsContextApi());

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }

    //on affiche le bouton retour
    this.showBackButton = true;

    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>